import { Address, HierarchyItem } from './address.model';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable id-denylist */
export interface PersonalInfo {
  profile: PersonalDataProfile;
  phones: PersonalDataPhones[];
  address: PersonalDataAddress;
  emergency_phone: PersonalDataPhones;
  email: string;
}

export interface PersonalDataProfile {
  name: string;
  first_surname: string;
  second_surname: string;
  identification_document: string;
}

export interface PersonalDataPhones {
  id: string;
  country_prefix_code: string;
  number: string;
  country_iso_numeric_code: string;
  type: string;
  is_principal: boolean;
  type_name: string;
  emergency_person_name?: string;
  emergency_person_kinship?: string;
  is_emergency?: boolean;
}

export interface PersonalDataAddress {
  address_id: string;
  is_principal: boolean;
  street_name: string;
  street_number: string;
  floor_number: string;
  stairway: string;
  door_number: string;
  street_type_id: string;
  street_type_name: string;
  country_iso_numeric_code: string;
  postal_code: string;
  hierarchy_code?: string;
  hierarchy: HierarchyItem[];
}
export class PersonalData {
  profile: PersonalDataProfile;
  phones: PersonalDataPhones[];
  address: Address;
  email: string;
  fullName: string;
  emergencyContact: PersonalDataPhones;

  constructor(data: PersonalInfo) {
    this.profile = data.profile;
    this.phones = data.phones;
    this.email = data.email;
    this.address = new Address(data.address);
    this.emergencyContact = data.emergency_phone;

    this.fullName = `${data.profile.name}  ${data.profile.first_surname} ${data.profile.second_surname}`;
  }

  getPrincipalPhone(): PersonalDataPhones | undefined {
    return this.phones.find((phone) => phone.is_principal);
  }

  getSecondaryPhone(): PersonalDataPhones | undefined {
    return this.phones.find((phone) => !phone.is_principal);
  }
}
export interface UpdatePhone {
  number: string;
  type: string;
  country_prefix_code: string;
  country_iso_numeric_code: string;
}

export interface UpdateEmergencyContact {
  number: string;
  country_prefix_code: string;
  country_iso_numeric_code: string;
  emergency_person_name: string;
  emergency_person_kinship?: string;
}

export enum PersonalDataSections {
  PROFILE = 'PROFILE',
  PHONE_CONTACT = 'PHONE_CONTACT',
  EMAIL_EDITION = 'EMAIL_EDITION',
  ADDRESS_EDITION = 'ADDRESS_EDITION',
  EMERGENCY_CONTACT = 'EMERGENCY_CONTACT_EDITION'
}

export interface PersonalDataItem {
  label?: string;
  value: string;
  isEditable?: boolean;
  cssClass?: string;
}

export interface PersonalDataSection {
  title: string;
  isEditable: boolean;
  data: PersonalDataItem[];
}
