import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from '@app/components/components.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { MPopoverModule } from '@mercadona/components/popover';

import { RangeSelectComponent } from './../payslips/components/dropdown-filters/date-select/date-range/date-range.component';
import { MonthPickerComponent } from './../payslips/components/dropdown-filters/date-select/month-picker/month-picker.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EditInfoComponent } from './edit-info/edit-info.component';
import { ErrorDisplayComponent } from './error-display/error-display.component';
import { FeedbackMsgComponent } from './feedback-msg/feedback-msg.component';
import { BankCodeValidationComponent } from './modals/bank-code-validation/bank-code-validation.component';
import { CodeValidationComponent } from './modals/code-validation/code-validation.component';
import { PhoneSelectionComponent } from './modals/phone-selection/phone-selection.component';
import { SecondFaContactComponent } from './modals/second-fa-contact/second-fa-contact.component';
import { SecondFaPhoneSelectionComponent } from './modals/second-fa-phone-selection/second-fa-phone-selection.component';
import { DirectDebitSuccessComponent } from './modals/success-modal/direct-debit-success/direct-debit-success.component';
import { SuccessModalComponent } from './modals/success-modal/success-modal.component';
import { YearPickerModule } from './year-picker/year-picker.module';

const components = [
  DropdownComponent,
  FeedbackMsgComponent,
  EditInfoComponent,
  SuccessModalComponent,
  RangeSelectComponent,
  DirectDebitSuccessComponent,
  PhoneSelectionComponent,
  CodeValidationComponent,
  BankCodeValidationComponent,
  SecondFaPhoneSelectionComponent,
  SecondFaContactComponent,
  ErrorDisplayComponent,
  MonthPickerComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule.forChild(),
    ComponentsModule,
    YearPickerModule,
    MPopoverModule
  ],
  declarations: components,
  entryComponents: [
    SuccessModalComponent,
    DirectDebitSuccessComponent,
    PhoneSelectionComponent,
    CodeValidationComponent,
    BankCodeValidationComponent,
    SecondFaPhoneSelectionComponent,
    SecondFaContactComponent
  ],
  exports: components
})
export class PtrabComponentsModule {}
