import { CountryResponse } from '@app/shared/interfaces/country/country.interface';

export class Country {
  hierarchyCode?: string;
  name: string;
  countryIsoNumericCode: string;

  constructor(data: CountryResponse) {
    this.hierarchyCode = data.hierarchy_code;
    this.name = data.name;
    this.countryIsoNumericCode = data.country_iso_numeric_code;
  }
}
