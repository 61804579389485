import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

export class YearDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    return `${date.getFullYear()}`;
  }
}

@Component({
  selector: 'app-ptrab-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: YearDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'YYYY' },
        display: { dateInput: 'YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' }
      }
    }
  ]
})
export class YearPickerComponent implements OnChanges {
  @Input() min!: number;
  @Input() max!: number;
  @Input() default!: number;
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Output() date = new EventEmitter<number>();

  isoDate!: Date;
  minDate!: Date;
  maxDate!: Date;
  defaultDate!: Date;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.default && this.default) {
      this.isoDate = new Date(this.default, 0);
    }

    this.setPickerYears();
  }

  onYearSelected(date: Date, datepicker: MatDatepicker<Date>) {
    const normalizedYear = date.getFullYear();
    datepicker?.close();
    this.date.emit(normalizedYear);
  }

  private setPickerYears() {
    const currentYear = new Date().getFullYear();

    this.minDate = new Date(this.min || currentYear - 100, 0);
    this.maxDate = new Date(this.max || currentYear, 0);
    this.defaultDate = new Date(this.default || currentYear, 0);
  }
}
