import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { ComponentsModule } from '@app/components/components.module';
import { TranslateModule } from '@ngx-translate/core';

import { YearDateAdapter, YearPickerComponent } from './year-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    ComponentsModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  declarations: [YearPickerComponent],
  providers: [{ provide: DateAdapter, useClass: YearDateAdapter }],
  exports: [YearPickerComponent]
})
export class YearPickerModule {}
