<div class="avatar" [ngClass]="size" (click)="onClick()">
  <section [class.news-ring]="hasNews && showNewsRing && pendingNews">
    <img *ngIf="avatar; else initials" class="avatar-image" [src]="avatar" alt="Avatar image" />
  </section>
</div>

<ng-template #initials>
  <div class="initials-container">
    <h2>{{ avatarInitials }}</h2>
  </div>
</ng-template>
