import { CountryResponse } from '@app/shared/interfaces/country/country.interface';
import { IdName } from '@app/shared/interfaces/data/idname.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { Country } from '../country/country.model';
/* eslint-disable @typescript-eslint/naming-convention */
export class Studies {
  qualifications: Study[];

  constructor(data: MSafeAny) {
    this.qualifications = data.qualifications;
  }
}

export interface StudyResponse {
  start_year: number;
  end_year: number | undefined;
  academy: string;
  country: CountryResponse;
  qualification: IdName;
  educational_level: IdName;
  qualification_status: QualificationStatus;
}

export class Study {
  startYear: number;
  endYear: number | undefined;
  academy: string;
  country: Country;
  qualification: IdName;
  educationalLevel: IdName;
  qualificationStatus: QualificationStatus;

  constructor(data: StudyResponse) {
    this.startYear = data.start_year;
    this.endYear = data.end_year;
    this.academy = data.academy;
    this.country = new Country(data.country);
    this.qualification = data.qualification;
    this.educationalLevel = data.educational_level;
    this.qualificationStatus = data.qualification_status;
  }
}

export class QualificationStatuses {
  qualification_statuses: QualificationStatus[];

  constructor(data: MSafeAny) {
    this.qualification_statuses = data.qualification_statuses;
  }
}

export class QualificationEducationalLevels {
  educational_levels: IdName[];

  constructor(data: MSafeAny) {
    this.educational_levels = data.educational_levels;
  }
}

export interface QualificationStatus {
  id: string;
  name: string;
  end_date: boolean;
  end_date_text: string;
}

export interface Qualification {
  data: IdName[];
  pagination: {
    requested_page: number;
    requested_size: number;
    retrieved_results: number;
    total_results: number;
  };
}
export class CreateQualification {
  country_iso_numeric_code: string;
  qualification_id: string;
  start_year: number;
  end_year?: number;
  qualification_status_id: string;
  academy: string;

  constructor(data: MSafeAny) {
    this.country_iso_numeric_code = data.country_iso_numeric_code;
    this.qualification_id = data.qualification_id;
    this.start_year = data.start_year;
    this.end_year = data?.end_year;
    this.qualification_status_id = data.qualification_status_id;
    this.academy = data.academy;
  }
}
