<div class="date-picker-material c-form-item">
  <input
    matInput
    readonly
    class="date-picker-material__input c-input"
    [placeholder]="placeholder"
    [matDatepicker]="datePicker"
    [(ngModel)]="isoDate"
    [max]="maxDate"
    [min]="minDate"
    (click)="datePicker?.open()" />
  <mat-datepicker-toggle matSuffix [for]="datePicker" class="date-picker-material__toggle"></mat-datepicker-toggle>
  <mat-datepicker
    #datePicker
    startView="multi-year"
    [startAt]="defaultDate"
    (yearSelected)="onYearSelected($event, datePicker)"></mat-datepicker>
</div>
