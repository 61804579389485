import { ActionsAnalytics } from '@app/services/analytics/models/analytics.enum';
import { ApiPtrabUrls } from '@ptrab/services/api/api.service';
import { Permissions } from '@shared/enums/permissions/permissions.enum';
import { NavTab } from '@shared/interfaces/nav-tab/nav-tab.interface';

import { Certificate } from '../shared/interfaces/certificate.interface';

const apiUrls = new ApiPtrabUrls();

export enum CertificatesTabs {
  EMPLOYMENT = 'EMPLOYMENT',
  PERMISSIONS_AND_LEAVES = 'PERMISSIONS_AND_LEAVES',
  REMUNERATION = 'REMUNERATION'
}

export const CERTIFICATE_NAV_TABS: NavTab[] = [
  {
    tabID: CertificatesTabs.EMPLOYMENT,
    label: 'EMPLOYEE_PORTAL.EMPLOYMENT',
    tabIcon: 'icon-employment',
    permissions: [Permissions.HIRING_CERTIFICATES, Permissions.CONTRACT_CERTIFICATE]
  },
  {
    tabID: CertificatesTabs.PERMISSIONS_AND_LEAVES,
    label: 'EMPLOYEE_PORTAL.PERMISSIONS_AND_LEAVES',
    tabIcon: 'icon-permissions',
    permissions: [Permissions.PERMISSIONS_AND_LEAVES_CERTIFICATES]
  },
  {
    tabID: CertificatesTabs.REMUNERATION,
    label: 'EMPLOYEE_PORTAL.REMUNERATION',
    tabIcon: 'icon-remuneration',
    permissions: [Permissions.REMUNERATION_CERTIFICATES]
  }
];

const employmentCertificateContract: Certificate = {
  title: 'EMPLOYEE_PORTAL.EMPLOYMENT_CERTIFICATE',
  description: 'EMPLOYEE_PORTAL.EMPLOYMENT_CERTIFICATE_DESCRIPTION',
  url: apiUrls.certificate.employment,
  analyticsLabel: ActionsAnalytics.EMPLOYMENT_CERTIFICATE_CONTRACT
};

const employmentLaboralSituationCertificate: Certificate = {
  title: 'EMPLOYEE_PORTAL.EMPLOYMENT_SITUATION',
  description: 'EMPLOYEE_PORTAL.EMPLOYMENT_SITUATION_DESCRIPTION',
  url: apiUrls.certificate.employmentSituation,
  analyticsLabel: ActionsAnalytics.EMPLOYMENT_CERTIFICATE_LABORAL_SITUATION
};

const employmentLaboralDayCertificate: Certificate = {
  title: 'EMPLOYEE_PORTAL.WORKING_DAY',
  description: 'EMPLOYEE_PORTAL.WORKING_DAY_DESCRIPTION',
  url: apiUrls.certificate.weeklyHours,
  analyticsLabel: ActionsAnalytics.EMPLOYMENT_CERTIFICATE_LABORAL_DAY
};

const employmentDayReductionCertificate: Certificate = {
  title: 'EMPLOYEE_PORTAL.WORKING_TIME_REDUCTION',
  description: 'EMPLOYEE_PORTAL.WORKING_TIME_REDUCTION_DESCRIPTION',
  url: apiUrls.certificate.workingTimeReduction,
  analyticsLabel: ActionsAnalytics.EMPLOYMENT_CERTIFICATE_DAY_REDUCTION
};

const employmentCertificates: Certificate[] = [
  employmentCertificateContract,
  employmentLaboralSituationCertificate,
  employmentLaboralDayCertificate,
  employmentDayReductionCertificate
];

const remunerationCertificates: Certificate[] = [
  {
    title: 'EMPLOYEE_PORTAL.STUDY_AID',
    description: 'EMPLOYEE_PORTAL.STUDY_AID_DESCRIPTION',
    url: apiUrls.certificate.studyAid,
    analyticsLabel: ActionsAnalytics.REMUNERATION_CERTIFICATE_STUDY_AIDS
  }
];

const permissionsAndLeavesCertificates: Certificate[] = [
  {
    title: 'EMPLOYEE_PORTAL.QUOTATIONS',
    description: 'EMPLOYEE_PORTAL.QUOTATIONS_DESCRIPTION',
    url: apiUrls.certificate.disability,
    analyticsLabel: ActionsAnalytics.PERMISSION_LEAVES_QUOTES
  }
];

export const CERTIFICATES_LISTS = {
  [CertificatesTabs.EMPLOYMENT]: employmentCertificates,
  [CertificatesTabs.REMUNERATION]: remunerationCertificates,
  [CertificatesTabs.PERMISSIONS_AND_LEAVES]: permissionsAndLeavesCertificates
};

export const CERTIFICATES_BY_PERMISSION: { permission: Permissions; certificate: Certificate }[] = [
  { permission: Permissions.CONTRACT_CERTIFICATE, certificate: employmentCertificateContract },
  { permission: Permissions.HIRING_CERTIFICATES, certificate: employmentLaboralSituationCertificate },
  { permission: Permissions.HIRING_CERTIFICATES, certificate: employmentLaboralDayCertificate },
  { permission: Permissions.HIRING_CERTIFICATES, certificate: employmentDayReductionCertificate }
];

export const CERTIFICATES_PT_BY_PERMISSION: { permission: Permissions; certificate: Certificate }[] = [
  { permission: Permissions.HIRING_CERTIFICATES, certificate: employmentLaboralSituationCertificate },
  { permission: Permissions.HIRING_CERTIFICATES, certificate: employmentLaboralDayCertificate }
];
